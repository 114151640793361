import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-06fc0f9d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "component-status-steps-box" }
const _hoisted_2 = { class: "step-header" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = {
  key: 0,
  class: "step-overlay"
}
const _hoisted_5 = { class: "step-overlay-loading-wrapper" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_loading = _resolveComponent("van-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataList, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "status-step-item"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", {
            textContent: _toDisplayString(item.title)
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", {
          class: "step-body",
          style: _normalizeStyle({ minHeight: _ctx.datqListMap[index]?.loading ? '70px':'unset', })
        }, [
          (_ctx.datqListMap[index]?.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_van_loading, {
                    type: "spinner",
                    color: "#1989fa"
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("div", {
            innerHTML: item.content
          }, null, 8, _hoisted_6), [
            [_vShow, _ctx.datqListMap[index]?.showBody]
          ])
        ], 4),
        _createElementVNode("div", {
          class: "step-side-dot",
          style: _normalizeStyle(_ctx.getDotStyle(index))
        }, null, 4),
        _createElementVNode("div", {
          class: "step-side-line",
          style: _normalizeStyle(_ctx.getLineStyle(index))
        }, null, 4)
      ]))
    }), 128))
  ]))
}