
import { Options, Vue } from 'vue-class-component';
import { getReportVersion8, getImgCodetVersion8 } from "@/api/index";
import ScoreNum from '../components/ScoreNum.vue';
import ReportSubTitleItem from '../components/ReportSubTitleItem.vue';
import ReportVersion8 from '@/views/share/components/ReportVersion8_0.vue';
import PersonInfoToast from '../components/PersonInfoToast.vue';
import Disclaimers from '../components/Disclaimers.vue';
import ImgCodeVersion7 from '@/views/share/components/ImgCodeVersion7_1.vue';
import { width } from 'dom7';

import wx from 'wx'
import html2canvas from 'html2canvas';

import { save as saveReportDesc, findSymptomTag,
  getByReportId,
  findAllTagByIds,
  getCommonlyUsedSymptomTag
} from '@/api/reportDesc'


const dataGraphMeaning= {
  "24小时心率分布图": {
    graphMeaning: "该图表达24小时心率实时分布状况。横轴表达了心率变化的实际发生时间，纵轴表达了心率的高低。绿色部分反映用户处于有效睡眠状态，灰色部分反映用户处于静息状态，红色部分反映用户处于运动状态，蓝色部分反映用户处于觉醒状态。",
  },
  "24小时Poincare散点图": {
    graphMeaning: "该图表达了24小时心率变异状况。横轴和纵轴表达了相邻的两个心动周期。正常情况下，散点分布在左下至右上连线的中点附近，较长、较宽且面积较大。",
  },
  "24小时体动分布图": {
    graphMeaning: "该图表达了关于生命活力的反映。横轴表达了体动变化的实际发生时间，纵轴表达了体动的多少。生命活力强的人表现为动作灵活多动，如儿童。生命活力弱的人表现为动作迟缓少动，如老年人。",
  },
  "24小时脉搏波变化图": {
    graphMeaning: "该图表达了用户的24小时脉搏波变化情况。横轴表达了脉搏波变化的实际发生时间，纵轴表达了脉搏波的高低；蓝线表示心肌收缩力，红线表示外周阻力。",
  },
}


@Options({
  components: {
    ScoreNum,
    ReportSubTitleItem,
    PersonInfoToast,
    Disclaimers,
    ReportVersion8,
    ImgCodeVersion7,
  },
  data() {

    return {
      
      saveAsImageLoading: false,


      heartReportData: {},
      liverReportData: {},
      kidneyReportData: {},
      spleenReportData: {},
      avatarDefault: require('@/assets/img/icon/user/默认头像@2x.png'),
      imgList: [],
      codeList: [],
      loading: false,
      showValidDataNoticeTip: true,
      showMoreTips: false,
      tipActiveNames: [0,1,2,3],
      topicTips: [
        {
          title: '数据有效率是什么',
          renderHtml: false,
          contents: [
              {
                content: '数据有效率是您实际佩戴手表的有效时长占比，通常数据有效率80%以上的报告 评估结果越准确。按一个佩戴周期24小时计算，80%约等于20小时，即每份报告有效佩戴满20小时为宜。',
                renderHtml: false,
              },
          ]
        },
        {
          title: '为什么会有无效数据',
          renderHtml: false,
          contents: [
                {
                  content: '1、在游泳、洗澡或充电期间，摘表的这段时间，会计入当前佩戴周期的无效时间，如中断时间超过4小时，这次出具的报告评估结果就不可靠。',
                  renderHtml: false,
                },
                {
                  content: '2、对于充电的手表或摘下的手表，一定不要将亮灯的一面触及桌面或其他物体，以免手表仍处于检测状态(亮灯)，产生虚假数据。虚假数据的心率约150左右，一般人不会达到这一水平。 ',
                  renderHtml: false,
                },
          ]
        },
        {
          title: '为什么要一次连续佩戴24小时',
          renderHtml: false,
          contents: [
                {
                  content: '自然界有昼夜节律，我们人体亦具有同样的昼夜节律。如血压白天高晚上低；胆汁分泌白天少晚上多。只有连续不间断地佩戴24小时，才能真实有效地反映一个小生命周期的变化，才具有充分的代表性。',
                  renderHtml: false,
                },
          ]
        },
        {
          title: '为什么要连续多日佩戴手表',
          renderHtml: false,
          contents: [
                {
                  content: '健康状态下，我们的功能状态都是相对稳定的，测评结果也变化不大。如果您的测评结果每天都变化很大，表明您身体的自我调节能力降低了，故需要连续佩戴手表。一般而言，如果您的测评数据在一周或10天内不发生比较大的变化，说明您的身体比较健康。可以在1-3个月后再做同样的检测。如果您的测评结果变化很大，就需要长期佩戴观察了。',
                  renderHtml: false,
                },
          ]
        },
      ],
      descPopup: {
        show: false,
        configProp: {
          lockScroll: false,
          closeable: true,
        },
        styleProp: {
          height: '40%',
          'box-shadow':'0 0 5px rgba(0,0,0,.2)',
          textAlign: 'left',
        },
        bodys: [
          {
            title: '',
            parseHtml: false,
            contents: [
              {
                content: '',
                parseHtml: false,
              }
            ]
          }
        ]
      },
      reportDescTagPopup: {
        show: false,
        // 显示建议列表
        showsuggestedList: false,
        // 检索值
        searchValue: '',
        // 提供的检索建议值列表
        searchSuggestedList: [],
        // 选中的症状名称集合（包含选择建议的项）
        reportSelectedTagNames: [],
        // 选择的标签项列表（包含选择建议的项）
        reportTagItem: [],
        remark: '',
        saveBtn: {
          disabled: true,
          loading: false,
          loadingText: "保存中",
        }
      },

      // 当前数据的报告ID
      reportId: null,
      version: null,
    }
  },
  created() {
    document.title = '五藏测评报告'

    this.reportId = this.$route.params.reportId;

    let tasks = [
      getReportVersion8(this.$route.params.reportId, 1).then((res: any) => {
        res.detailInfo = res.detailInfo.filter(item => {
          return item.detailName.indexOf('混沌') === -1
        })
        this.heartReportData = res
      }),
      getReportVersion8(this.$route.params.reportId, 2).then((res: any) => {
        this.liverReportData = res
      }),
      getReportVersion8(this.$route.params.reportId, 3).then((res: any) => {
        this.kidneyReportData = res
      }),
      getReportVersion8(this.$route.params.reportId, 5).then((res: any) => {
        this.spleenReportData = res
      }),
      // 获取心率图和指标项数据
      getImgCodetVersion8(this.$route.params.reportId).then((res: any) => {
        res.imgList.forEach(v=>{
          Object.assign(v,dataGraphMeaning[v.name])
        })

        this.imgList = res.imgList;
        this.codeList = res.codeList.filter(item => {
          return item.name.indexOf('HRCHA') === -1
        });
      }),
    ];

    Promise.allSettled(tasks).finally(()=>{
      this.loading = false;
    })

    
    this.version = localStorage.getItem('report8_0_version')

  },
  methods: {

    /**
     * 加载报告描述信息
     */
    loadReportDescInfo(){

      getByReportId(this.reportId).then(data => {
        if(data){
          this.reportDescTagPopup.remark= data.remark;
          this.reportDescTagPopup.reportTagItem = [];

          findAllTagByIds(data.tags).then(d => {
            const selectedItems = d.map(selectedItem => {
              return {
                label: selectedItem.tagName,
                value: selectedItem.id,
                // 是否处于选中状态，选中1，未选中0
                checked: 1,
                isNew: false
              };
            })
  
            this.reportDescTagPopup.reportTagItem = selectedItems
            this.reportDescTagPopup.reportSelectedTagNames.push(...selectedItems.map(v => v.value))
  
            this.reportDescTagPopup.saveBtn.disabled = false;
          }).then(_=>{


            // 获取常用的标签列表
            getCommonlyUsedSymptomTag().then(d => {
              const commonlyUsedItems = d.map(selectedItem => {
                return {
                  label: selectedItem.tagName,
                  value: selectedItem.id,
                  checked: 0,
                  isNew: false
                };
              })
              
              // 将标签附加在最后的位置
              this.reportDescTagPopup.reportTagItem.push(...commonlyUsedItems.filter(item => this.reportDescTagPopup.reportTagItem.every(item2 => item2.value !== item.value) ))

            })


          })
        }else{

          // 获取常用的标签列表
          getCommonlyUsedSymptomTag().then(d => {
            const commonlyUsedItems = d.map(selectedItem => {
              return {
                label: selectedItem.tagName,
                value: selectedItem.id,
                checked: 0,
                isNew: false
              };
            })
            
            // 将常用的标签赋值
            this.reportDescTagPopup.reportTagItem = commonlyUsedItems

          })


          this.reportDescTagPopup.saveBtn.disabled = false;
        }

      })

    },

    /**
     * 检索症状标签的值更新时触发
     */
    handlerUpdateSearchSymptomTagValue(value,showSuggestedList = true){
      if(value){

        // 限制字数，逻辑判断处理下。
        if(value && value.length > 15){
          this.$toast.fail({
            message: '超出最大字符数',
            closeOnClick: true,
            closeOnClickOverlay: false,
            overlay: false,
            duration: 2000,
          });
          return;
        }


        findSymptomTag(value).then(data => {

          const tagList = data.map(item => {
            return {
              label: item.tagName,
              value: item.id,
              // 是否是新的创建，如果是则在保存的时候会自动创建。
              isNew: false,
            };
          })

          // 判断是否有完全匹配项，如果没有插入一条新的创建的记录作为第一条
          if(tagList.every(ele => ele.label !== value)){
            tagList.unshift({
              label: value,
              value: value,
              // 是否是新的创建，如果是则在保存的时候会自动创建。
              isNew: true,
            })
          }

          this.reportDescTagPopup.searchSuggestedList = tagList
          if(showSuggestedList){
            this.reportDescTagPopup.showsuggestedList = true;
          }
        })
      }else{
        this.reportDescTagPopup.searchSuggestedList = []
        this.reportDescTagPopup.showsuggestedList = false;
      }
    },
    /**
     * 处理检索出来的建议项的click点击处理
     */
    handleClickByReportSuggestItem(item){
      console.log("handleClickByReportSuggestItem", item);
      
      const reportSelectedTagNames = this.reportDescTagPopup.reportSelectedTagNames
      const reportTagItem = this.reportDescTagPopup.reportTagItem;

      console.log("选中标签信息", item, reportSelectedTagNames, this.reportDescTagPopup.reportSelectedTagNames);
      
      if(reportSelectedTagNames.includes(item.value)){
        this.$toast.fail({
          message: '当前已选中，请勿重复选择',
          closeOnClick: true,
          closeOnClickOverlay: false,
          overlay: false,
          duration: 2000,
        });
        return;
      }

      if(reportTagItem.findIndex(candidateItem=>{
        return candidateItem.value === item.value;
      }) > -1){
        this.$toast.fail({
          message: '当前项已在候选项中，请进行手动选中',
          closeOnClick: true,
          closeOnClickOverlay: false,
          overlay: false,
          duration: 2000,
        });
        return;
      }

      reportTagItem.push({
        label: item.label,
        value: item.value,
        checked: 1,
        isNew: item.isNew
      })

      /**
       * 返回有序的症状标签集合。顺序规则：选中的靠前展示。
       */
      this.reportDescTagPopup.reportTagItem.sort((a,b) => {
        return b.checked - a.checked;
      })

      reportSelectedTagNames.push(item.value)

    },
    /**
     * 处理报告标签选中的处理
     * @param names 
     */
    handleReportTagItem(names){
      console.log("handleReportTag", names);

      // 实时处理保存的逻辑代码
      const reportTagItem = this.reportDescTagPopup.reportTagItem;
      reportTagItem.forEach(item => {
        if(names.includes(item.value)){
          item.checked = 1;
        }else{
          item.checked = 0;
        }
      });

      /**
       * 返回有序的症状标签集合。顺序规则：选中的靠前展示。
       */
      this.reportDescTagPopup.reportTagItem.sort((a,b) => {
        return b.checked - a.checked;
      })
      
    },
    saveReportDesc(){

      try {
        this.reportDescTagPopup.saveBtn.loading = true;

        const reportTagItem = this.reportDescTagPopup.reportTagItem;
        const remark = this.reportDescTagPopup.remark;
        let tags = reportTagItem
        .filter(item => item.checked === 1)
        .map(item =>{
          return `${item.isNew?"":item.value}:${item.label}`
        }).join(',')
        
        saveReportDesc({
          reportId: this.reportId,
          tags: tags,
          remark: remark,
        }).then(()=>{

          this.$toast.success({
          message: '保存成功',
          closeOnClick: true,
          closeOnClickOverlay: false,
          overlay: false,
          duration: 2000,
        });

        // 刷新检索的建议值列表 
          this.handlerUpdateSearchSymptomTagValue(this.reportDescTagPopup.searchValue, false)

          // 保存后刷新下保存的数据
          this.loadReportDescInfo();

        }).finally(()=>{
          this.reportDescTagPopup.saveBtn.loading = false;
        });
      } catch(err) {
        this.reportDescTagPopup.saveBtn.loading = false;
      }

    },
    saveAsImage(){
      let that = this;
      try {
          let useScale = window.devicePixelRatio;
          if(useScale > 1){
            useScale = 1.5;
          }
          console.log("html2canvas 使用DPI{}进行渲染图片", useScale);
          

          this.saveAsImageLoading = true;
          html2canvas(document.body,{
              scrollY: 0,
              allowTaint: true,
              useCORS: true,
              backgroundColor: "#eeeff4",
              logging: false,
              scale: useScale,
          }).then(function(canvas) {
              let dataUrl = canvas.toDataURL("image/jpeg", 1);
              console.log("POST dataUrl",dataUrl);
              
              wx.miniProgram.postMessage({
                  data: {
                      documentDataUrl: dataUrl,
                      extendType: 'jpeg'
                  }
              });
              
              // 关闭当前页面，以触发PostMessage的绑定消息处理
              // wx.miniProgram.navigateBack({
              //     delta: 1,
              // })

              // 刷新当前详情页面，相当于触发了PostMessage的绑定消息处理
              wx.miniProgram.redirectTo({
                  url: `/pages/share/report7_1/report7_1?id=${that.$route.params.reportId}&memberId=${that.$route.params.memberId}`,
              });
              // document.body.appendChild(canvas)

          }).finally(()=>{
              this.saveAsImageLoading = false;
          });

      } catch (e) {
          this.saveAsImageLoading = false;   
          this.$toast.fail("生成图片失败", e)
          console.error("保存为图片失败，异常信息：", e)
      }
  },
    onRefresh  ()  {
      location.reload();
    },
    openDescPopup(item: {
      /**
       * 指标标题
       */
      title: string;
      titleIsHtml?: boolean;
      /**
       * 指标含义
       */
      content: string;
      contentIsHtml?: boolean;
    },dom){
      console.log("openDescPopup",item,dom);
      

      item.titleIsHtml = item.titleIsHtml  || false; 
      item.contentIsHtml = item.contentIsHtml  || false; 

      this.descPopup.styleProp.height = '40%';
      this.descPopup.configProp.lockScroll = false;

      this.descPopup.bodys = [
        {
          title: item.title,
          parseHtml: item.titleIsHtml,
          contents: [
            {
              content: item.content,
              parseHtml: item.contentIsHtml,
            }
          ]
        },
      ]

      // 准备滚动到目标位置，展开说明信息
      if(dom){
        if(dom.getBoundingClientRect){
          this.descPopup.styleProp.height = `calc(100% - ${dom.getBoundingClientRect().height}px)`
          if(dom.scrollIntoView){
            dom.scrollIntoView({
              behavior:'smooth',
              block:'start',
            })
            this.descPopup.configProp.lockScroll = true;
          }else{
            console.warn("DOM scrollIntoView 函数不支持");
          }
        }else{
          console.warn("DOM getBoundingClientRect 函数不支持");
        }
      }
      this.descPopup.show = true;
    }
  },
})
export default class ExpertReport extends Vue {}
