import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-62bb4dd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "iconbutton" }
const _hoisted_2 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_van_icon = _resolveComponent("van-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.img)
      ? (_openBlock(), _createBlock(_component_van_image, {
          key: 0,
          round: "",
          width: "0.66667rem",
          height: "0.66667rem",
          src: _ctx.img
        }, null, 8, ["src"]))
      : _createCommentVNode("", true),
    (!_ctx.img && _ctx.icon)
      ? (_openBlock(), _createBlock(_component_van_icon, {
          key: 1,
          name: _ctx.icon,
          class: _normalizeClass(_ctx.iconClass),
          color: _ctx.iconColor
        }, null, 8, ["name", "class", "color"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.name), 1)
  ]))
}