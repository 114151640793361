import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "num"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.score)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, "-"))
    : (_openBlock(), _createElementBlock("span", {
        key: 1,
        class: _normalizeClass(["num", {'red': parseInt(_ctx.score) < 60, 'orange': parseInt(_ctx.score) >= 60 && parseInt(_ctx.score) < 80, 'green': parseInt(_ctx.score) >= 80}])
      }, _toDisplayString(parseInt(_ctx.score)) + _toDisplayString(_ctx.addZero ? '.0' : ''), 3))
}