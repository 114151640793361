import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e1d205ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-questionnaire-list-box" }
const _hoisted_2 = { style: {"text-align":"left"} }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { style: {"position":"fixed","right":"10%","bottom":"15%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_tag = _resolveComponent("van-tag")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_list = _resolveComponent("van-list")!
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh")!

  return (_openBlock(), _createBlock(_component_van_pull_refresh, {
    modelValue: _ctx.pullRefresh.loading,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pullRefresh.loading) = $event)),
    onRefresh: _ctx.onRefresh
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_van_list, {
          ref: "list",
          loading: _ctx.pageStatus.loading,
          "onUpdate:loading": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageStatus.loading) = $event)),
          finished: _ctx.pageStatus.finished,
          "finished-text": "没有更多了",
          onLoad: _ctx.onLoad,
          "immediate-check": false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_van_cell_group, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageStatus.data, (item) => {
                  return (_openBlock(), _createBlock(_component_van_cell, {
                    key: item,
                    clickable: "",
                    icon: "description",
                    value: item.createTime,
                    onClick: ($event: any) => (_ctx.onClick(item.id, item))
                  }, {
                    title: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        (item.type === 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "调查问卷"))
                          : (item.type === 2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "睡眠问卷"))
                            : (_openBlock(), _createElementBlock("span", _hoisted_5, "无效问卷")),
                        (!item.nativeCreator)
                          ? (_openBlock(), _createBlock(_component_van_tag, {
                              key: 3,
                              class: "unset-back-color",
                              mark: "",
                              "text-color": "rgb(159, 102, 204)",
                              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.tipsMessage('此记录是当前登录成员所属的用户进行创建的记录')), ["stop"]))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.memberName) + "用户创建 ", 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["value", "onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading", "finished", "onLoad"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_van_button, {
          style: {"width":"3em","height":"3em"},
          round: "",
          icon: "plus",
          color: "rgb(159, 102, 204)",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toNewUserQuestionnaire()))
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "onRefresh"]))
}