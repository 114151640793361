import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-28f5a876"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scheme-custom-wrapper" }
const _hoisted_2 = { class: "scheme-custom-box" }
const _hoisted_3 = {
  key: 0,
  class: "scheme-custom-item__title"
}
const _hoisted_4 = ["textContent"]
const _hoisted_5 = { class: "scheme-custom-item__body" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_col = _resolveComponent("van-col")!
  const _component_van_row = _resolveComponent("van-row")!
  const _component_van_sticky = _resolveComponent("van-sticky")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "scheme-custom-item",
          key: index
        }, [
          (item.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("span", {
                  textContent: _toDisplayString(item.title)
                }, null, 8, _hoisted_4)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            (item.isRichText)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  innerHTML: item.content
                }, null, 8, _hoisted_6))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  textContent: _toDisplayString(item.content)
                }, null, 8, _hoisted_7))
          ])
        ]))
      }), 128)),
      _createVNode(_component_van_sticky, {
        "offset-bottom": 20,
        position: "bottom",
        "safe-area-inset-bottom": "",
        style: {"margin":"25px 0"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_van_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_van_col, {
                span: "20",
                offset: "2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_van_button, {
                    onClick: _ctx.toHealthPage,
                    type: "primary",
                    color: "rgb(116, 83, 213)",
                    round: true,
                    size: "normal",
                    block: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("查看睡眠改善方案")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}