import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "trend" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TrendItem = _resolveComponent("TrendItem")!
  const _component_van_tab = _resolveComponent("van-tab")!
  const _component_van_tabs = _resolveComponent("van-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_tabs, {
      active: _ctx.active,
      "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_tab, { title: "最近7天" }, {
          default: _withCtx(() => [
            _createVNode(_component_TrendItem, { dayLength: 7 })
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "最近1月" }, {
          default: _withCtx(() => [
            _createVNode(_component_TrendItem, { dayLength: 30 })
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "最近3月" }, {
          default: _withCtx(() => [
            _createVNode(_component_TrendItem, { dayLength: 90 })
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "最近6月" }, {
          default: _withCtx(() => [
            _createVNode(_component_TrendItem, { dayLength: 180 })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}